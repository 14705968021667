import { Box } from "@mui/material";

import Page from "src/components/Page";
import RootStyle from "../shared/rootStyle";
import Header from "./components/Header";
import ImageCard from "./components/ImageCard";
import MainSection from "./components/MainSection";
import "./style.css";

const Login = () => {
  return (
    <Page title="Login" sx={{ backgroundColor: "#ffd18e", }}>
      <RootStyle>
        <Header />

        <Box className="loginmainBox">
          <ImageCard />
          <MainSection />
        </Box>
      </RootStyle>
    </Page>
  );
};

export default Login;
