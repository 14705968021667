import { styled } from "@mui/material/styles";
import LoginTopImg from "src/images/loginImg.png";
import GetStartedTitle from "./GetStartedTitle";

const Wrapper = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",

}));

const Header = () => (
  <Wrapper>
    <img src={LoginTopImg} className="LoginTopImg"  />

    <GetStartedTitle />
  </Wrapper>
);

export default Header;
