import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoginForm } from "src/sections/auth/login";
import Message from "./Message";
import NoAccountSection from "./NoAccountSection";
import "../../style.css";

import LogoSparis from "src/images/sparissimo_logo.png";


const ContentStyle = styled("div")(({ theme }) => ({
  width: "420px",
  // display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "60px 40px",
  borderRadius: "12px",
  background: "#ffd9a0",
  border: "1px solid #ffac32",
}));

const MainSection = () => {
  return (


    <ContentStyle>

      <img src={LogoSparis} className="loginMobileLogo" />


      <Message />
      <LoginForm />

      <NoAccountSection />
    </ContentStyle>


  );
};

export default MainSection;
