import { Box, Typography } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import Image from "src/components/Image";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
// import LoginBanner from "src/images/login.webp";
import LogoSparis from "src/images/sparissimo_logo.png";

const ImageCard = () => {
  const mdUp = useResponsive("up", "md");
  const { translate } = useLocales();
  return (
    mdUp && (
      // <SectionStyle>
      <Box class="LoginCardBox">
        <img src={LogoSparis} style={{ width: "auto", px: 5, mt: 10, maxWidth: "350px" }} />

        <Typography variant="h3" sx={{ mt: 3, }}>
          {translate("login.Himsg")}
        </Typography>
        <Typography sx={{ my: 2 }}>
        {translate("login.Hicontnt")}
          
        </Typography>
      </Box>
      // </SectionStyle>
    )
  );
};

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: "100%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   margin: theme.spacing(2, 0, 2, 2),
//   boxShadow:"none",
//   backgroundColor:"transparent",
//   paddingLeft:"25px",


// }));

export default ImageCard;
