import { Box, Card, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";

const Cards = ({ title, color, total, icon, currency, tooltip }) => {
    const { translate } = useLocales();
    return (
        <Tooltip title={translate(tooltip)}>
            <Card
                sx={{
                    p: 1.7,
                }}
            >
              <Box sx={{ display: "flex",justifyContent:"space-between", color: "text.secondary",}}>
              <Typography variant="subtitle1" sx={{color:"text.secondary",fontSize:"14px"}}>{title}</Typography>

                <Box>
                        <Iconify
                            color={color}
                            width={36}
                            height={36}
                            icon={icon}
                        />
                    </Box>
                    </Box>

                   
                    <Box sx={{ display: "flex", mt:3 }}>
                    <Typography variant="h6">
                        {currency} {total}
                    </Typography>
                </Box>
            </Card>
        </Tooltip>
    );
};

Cards.propTypes = {
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
};

export default Cards;
